<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            type="year"
            label="평가년도"
            name="assessmentYears"
            v-model="searchParam.assessmentYears"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분-정기/수시/최초"
            v-model="searchParam.ramAssessTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="위험성평가 목록"
          :columns="grid.columns"
          :data="grid.data"
          :gridHeight="grid.height"
          selection="multiple"
          rowKey="ramRiskAssessmentPlanId"
          @changeSelection="changeSelection"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
              <c-btn label="선택" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-tab
          type="tabcard"
          align="left"
          height="600px"
          :tabItems.sync="tabItems"
          inlineLabel
          v-model="tab"
        >
          <template v-slot:default="tab">
            <component
              :is="tab.component"
              :popupParam.sync="popupParam"
              :summary.sync="summary"
              :assessmentName="tab.label"
              :ramRiskAssessmentPlanIds="ramRiskAssessmentPlanIds"
              :ramRiskAssessmentPlanId="tab.name"
            />
          </template>
        </c-tab>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'reCheckAssessmentPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramTechniqueCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentYears: [],
        ramAssessTypeCd: null,
        ramStepCd: 'RRS0000015', // 완료된 정보만 (재검토임으로)
        ramTechniqueCd: '',
        regUserId: '',
        ramRiskAssessmentPlanId: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            label: '평가기간',
            align: 'center',
            style: 'width:170px',
            sortable: true
          },
          {
            name: 'ramAssessTypeName',
            field: 'ramAssessTypeName',
            label: '평가<br/>구분',
            align: 'center',
            style: 'width:60px',
            sortable: true
          },
        ],
        height: '600px',
        data: [],
      },
      tab: 'summary',
      tabDefaultItems: [
        {
          name: 'summary', 
          label: '합산', 
          component: () => import(`${'./reCheckSummary.vue'}`)
        }
      ],
      summary: {
        data: [],
      },
      selectRisks: [],
      editable: true,
      listUrl: '',
      linkRow: null,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    ramRiskAssessmentPlanIds() {
      let ids = [];
      if (this.tabItems && this.tabItems.length > 1) {
        // summary 제외한 항목이 존재하는 경우
        for (let idx = 1; idx < this.tabItems.length; idx++) {
          ids.push(this.tabItems[idx].name)
        }
      }
      return ids;
    },
    tabItems() {
      let items = this.tabDefaultItems;
      if (this.selectRisks && this.selectRisks.length > 0) {
        items = this.$_.concat(items, this.$_.map(this.selectRisks, risk => {
          return {
            name: risk.ramRiskAssessmentPlanId, 
            label: risk.assessmentName, 
            component: () => import(`${'./reCheckSecnario.vue'}`)
          }
        }))
      }
      return items
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.assessPlan.list.url
      // code setting
      this.$set(this.searchParam, 'ramTechniqueCd', this.popupParam.ramTechniqueCd);
      this.$set(this.searchParam, 'ramRiskAssessmentPlanId', this.popupParam.ramRiskAssessmentPlanId);
      this.$set(this.searchParam, 'regUserId', this.$store.getters.user.userId);
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    changeSelection() {
      let selectData = this.$refs['table'].selected;
      this.$set(this.$data, 'selectRisks', selectData)
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData, this.summary.data);
      }
    },
  }
};
</script>
